import { StudentHandPosition } from '../student/student';

export class UserProfile {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
  isStudent?: boolean;
  isPrivileged?: boolean;
  defaultAssignmentDirectoryId: number;
  defaultTestDirectoryId: number;
  defaultPracticeTestDirectoryId: number;
  schoolStartTime: string;
  schoolEndTime: string;
  schoolTimeAvailable: boolean;
  timeZone: string;
  studentPosition: StudentHandPosition;
  currentVersion: string;
  lastLoginDate?: Date;
  completeHourCertificateIssueDate?: Date;
  completeHourCertificateLink: string;
  certificates: CertificateModel[];
  hasUnReadChats: boolean;
  isToasterAdmin: boolean;
}

export class CertificateModel {
  id: number;
  completeHour: string;
  completeHourCertificateIssueDate: Date;
  completeHourCertificateLink: string;
  type: string;
}

export class GetUserTokenResponse {
  token: string;
  refreshToken: string;
}

export class ManuallyChangePassword {
  oldPassword: string;
  newPassword: string;
}

export class CloneTeacherRequest {
  fromTeacherId: number;
  newTeacherEmail: string;
  firstName: string;
  lastName: string;
  password: string;
}

export class CertificateDetailModle {
  teacherName: string;
  issueDate: Date;
  spentHour: string;
}

export class IssueCertificateModel {
  teacherId: number;
  spentHours: number;
}

export class AddRegistrationRequests {
  firstName: string;
  lastName: string;
  email: string;
  requestedForDemo: boolean;

}

export class RegistrationRequest {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  createdDate: Date;
  requestForDemo: boolean;
  whereDidYouHear:string;
}

export class RegistrationRequestById {
  firstName: string;
  lastName: string;
  email: string;
  status: string;
}
