import {AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors} from '@angular/forms';
import {
  AssignmentFilterRequest,
  AssignmentSubTypes,
  AssignmentTypes,
  AudioTypesEnum,
  FeedBackQuestion,
  IntroSlide,
  RecordingTypesEnum
} from '../assignment/assignment';
import {UserProfile} from '../user/userProfile';
import {createAction, createFeatureSelector, createSelector, props} from '@ngrx/store';
import {ExternalLogin, LoginResponse} from '../login/login';
import {ITreeState} from '@circlon/angular-tree-component';
import {DirectoryType} from '../directory';
import {IStudentTestDetails} from '../test/test';
import {ErrorStateMatcher} from '@angular/material/core';
import {sessionUserData, showHelpPopup} from "@mptl/web/root-store";
import {ChatAdmin} from '../chat/chat';


export class BaseResponse<T, TRequest> {
  public data: T;
  public status: 'success' | 'fail';
  public hasError: boolean;
  public errors: MessageData[];
  public request?: TRequest;
  public queryString?: any;
}

export class MessageData {
  public type: 'Error' | 'Info';
  public title: string;
  public message: string;
}

export class PaginatedResponse<P> {
  data: P;
  pageLength: number;
  pageNumber: number;
  totalEntityCount: number;
  totalPageCount: number;
}

export class BaseFilterRequest {
  pageNo = 1;
  pageSize = 10;
  search? = '';
  sortOrder?: 'asc' | 'desc' = 'desc';
  sortBy?: string = '';
  filterBy?: string = '';
}

export class DirectoryPaginatedRequest extends BaseFilterRequest {
  directoryId: number;
}

export interface BaseOption {
  id: number;
  name: string;
  displayName: string;
  subscriptionCharge?: number;
  description?: string;
  baseSubscriptionId?: number;
  subscriptionStatus?: 'SUBSCRIBED' | 'UNSUBSCRIBED' | 'PENDING';
  subscriptionExpiry?: Date;
}

export interface BaseFileResponse {
  fileId: string;
  url: string;
}

export class UploadFileResponse {
  fileKey: string;
  url: string
}

export class IdNameModel {
  id: number;
  name: string;
}

export class IGroupDropDown extends IdNameModel {
  groupType: AssignmentTypes;
  hidden: boolean = false;
}

export class IdNameModelExtended {
  id: number;
  name: string;
  idStr: string;
  type: AssignmentTypes;
  subType: AssignmentSubTypes;
  totalRecordingAvailable: number;
  totalWord: number;
  introductionSlides?: IntroSlide[];
  feedbackQuestions?: FeedBackQuestion[];
}

export class IAssignmentListForDropDown extends IdNameModel {
  type: AssignmentTypes;
}

export const emailRegex = '^([\\w\\.\\-]+)@([\\w\\-]+)((\\.(\\w){2,3})+)$';
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/;

export function noWhitespaceValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (control?.value) {
    const isWhitespace = (control.value || '')?.trim()?.length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {whitespace: true};
  }
  return null;
}

export type BaseEnumNames =
  'AudioConfigurationEnum'
  | 'RecordingBehaviourEnum'
  | 'DirectoryType'
  | 'ReminderTriggerSettingValue'
  | 'MarketPlaceGradeEnum';

export interface IBaseOption {
  name: string;
  value: string;
  hidden?: boolean;
}

export interface IAudioTypesOption {
  name: string;
  value: AudioTypesEnum;
  hidden?: boolean;
}

export interface IRecordingBehaviourOption {
  name: string;
  value: RecordingTypesEnum;
  hidden?: boolean;
}

export interface IAppState {
  root: IRootState;
  canUseStudentApp: boolean | undefined;
  canRotateDevice: boolean | undefined;
  canFinalGradeDateRange: { startDate: Date, endDate: Date } | undefined;
  canGradingResultsDateRange: { startDate: Date, endDate: Date } | undefined;
  sessionUserData: LoginResponse | undefined
  firstTestCreated: boolean | undefined;
  showHelpPopup: boolean;
}

export interface IRootState {
  token: string | null;
  user: UserProfile | null;
  externalLoginInProcess: boolean;
  userLocalConfigs: IUserLocalConfigs;
  errors: MessageData[];
  studentCode: string;
  chatAdmin: ChatAdmin;
  notificationPermission: string;
  clipboardPermission: string;
  permissionAsked: boolean;
}

export interface IUserLocalConfigs {
  assignmentRequest: AssignmentFilterRequest | undefined;
  dashboardRecordsSettings: { [id: number]: boolean };
  testGradeRecordsSettings: { [id: number]: boolean };
  treeState: ITreeState;
  treeStateFromMoveToDirectory: ITreeState;
  practiceTestStudentWordSettings: { [id: number]: boolean };
  fluencyResultsSettings: { [id: number]: boolean };
  practiceTestDrawingPadLocalSettings: {
    [testId: number]: {
      testDetails: IStudentTestDetails;
      testEndTime: Date;
    }
  };
}

export enum AppInsightsEvents {
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOG_OUT = 'USER_LOG_OUT',
}

export const LoginCompleteAction = createAction(
  '[AUTH] Login Complete',
  props<{ response: LoginResponse }>()
);
export const LoginErrorAction = createAction(
  '[AUTH] Login Error',
  props<{ errors: MessageData[] }>()
);
export const LogoutAction = createAction('[AUTH] Logout');

export const GoogleLoginAction = createAction(
  '[AUTH] Google Login',
  props<{ request: ExternalLogin }>()
);

export const SetUserAction = createAction(
  '[AUTH] Set User Action',
  props<{ user: UserProfile }>()
);

export const SetDashboardRecordSetting = createAction(
  '[Auth] Set Dashboard Record Setting',
  props<{ id: number; value: boolean }>()
);

export const SetTestGradeRecordSetting = createAction(
  '[Auth] Set Test Grade Record Setting',
  props<{ id: number; value: boolean }>()
);

export const SetPracticeTestStudentWordSettings = createAction(
  '[Auth] Set Supplemental Practice Student Word Settings',
  props<{ id: number; value: boolean }>()
);

export const SetFluencyResultsSettings = createAction(
  '[General] Set Fluency Results Settings',
  props<{ id: number; value: boolean }>()
);

export const SetAssignmentRequestSettings = createAction(
  '[General] Set Assignment Request Settings',
  props<{ request: AssignmentFilterRequest }>()
);

export const SetStudentCode = createAction(
  '[Student] Set Student Code',
  props<{ studentCode: string }>()
);

export const ChangeTreeState = createAction(
  '[Assignment] Change TreeS tate',
  props<{ treeState: ITreeState }>()
);

export const ChangeTreeStateFromMoveToDirectory = createAction(
  '[Assignment] Change TreeS tate From Move To Directory',
  props<{ treeState: ITreeState }>()
);

export const SetPracticeTestDrawingPadLocalSettings = createAction(
  '[Assignment] Set Practice Test DrawingPad Local Settings',
  props<{
    data: {
      [testId: number]: {
        testDetails: IStudentTestDetails;
        testEndTime: Date;
      }
    }
  }>()
);

export const RemovePracticeTestByIdDrawingPadLocalSettings = createAction(
  '[Assignment] Remove Practice Test By Id DrawingPad Local Settings',
  props<{ testId: number }>()
);
export const SetCanUseStudentApp = createAction(
  '[root] Set Can Use Student App',
  props<{ state: boolean }>()
);

export const SetCanRotateDevice = createAction(
  '[root] Set Can Rotate Device',
  props<{ state: boolean }>()
);

export const SetFinalGradeDateRange = createAction(
  '[root] Set Final Grade Date Range',
  props<{ state: { startDate: Date, endDate: Date } }>()
);

export const SetGradingResultsDateRange = createAction(
  '[root] Set Grading Results Date Range',
  props<{ state: { startDate: Date, endDate: Date } }>()
);

export const DontShowHelpPopup = createAction(
  '[root] Do not Show Help Popup'
);


export const SetSessionUserData = createAction(
  '[root] Set Session User Data',
  props<{ response: LoginResponse }>()
);

export const ResetErrorAction = createAction('[AUTH] ResetError');
export const FirstTestCreatedCompleted = createAction('[tour] FirstTestCreatedCompleted');

export const SetHasUnReadChats = createAction('[AUTH] Set Has UnRead Chats', props<{ hasUnReadChats: boolean }>());

export const SetChatAdmin = createAction(
  '[CHAT] Set Chat Admin',
  props<{ chatAdmin: ChatAdmin }>()
);

export const SetNotificationPermission = createAction('[AUTH] Set Notification Permission', props<{ data: string }>());
export const SetClipBoardPermission = createAction('[AUTH] Set Clip Board Permission', props<{ data: string }>());
export const SetPermissionAsked = createAction('[AUTH] Set Permission Asked', props<{ data: boolean }>());

/** root Selectors **/
const rootState = createFeatureSelector<IRootState>('root');
const CanIUseState = createFeatureSelector<boolean>('canUseStudentApp');
const CanRotateDeviceIUseState = createFeatureSelector<boolean>('canRotateDevice');
const CanFinalGradeDateRangeState = createFeatureSelector<{ startDate: Date, endDate: Date }>('canFinalGradeDateRange');
const CanGradingResultsDateRangeState = createFeatureSelector<{
  startDate: Date,
  endDate: Date
}>('canGradingResultsDateRange');
const selectSessionUserData = createFeatureSelector<LoginResponse>('sessionUserData');
const firstTestCreated = createFeatureSelector<boolean>('firstTestCreated');
export const showHelpPopupState = createFeatureSelector<boolean>('showHelpPopup');
export const selectShowHelpPopup = createSelector(showHelpPopupState, (state) => state);
export const firstTestCreatedCompleted = createSelector(firstTestCreated, (state) => state)
export const getToken = createSelector(rootState, (state) => state.token);
export const getSessionToken = createSelector(selectSessionUserData, (state) => state?.token);
export const isSessionUser = createSelector(selectSessionUserData, (state) => state?.isSessionUser);
export const sessionUser = createSelector(selectSessionUserData, (state) => state?.user);
export const rootUser = createSelector(rootState, (state) => state.user);

export const getCurrentUserId = createSelector(rootState, (state) => state?.user?.id);

export const getChatAdminId = createSelector(rootState, (state) => state?.chatAdmin?.id);
export const getChatAdmin = createSelector(rootState, (state) => state?.chatAdmin);

export const getHasUnReadChats = createSelector(rootState, (state) => state?.user?.hasUnReadChats);

export const currentUser = createSelector(sessionUser, rootUser, (sessionData, rootState): UserProfile => sessionData ?? rootState)
export const getStudentCode = createSelector(
  rootState,
  (state) => state.studentCode
);
export const isStudent = createSelector(
  currentUser,
  (state) => state?.isStudent
);
export const isAdmin = createSelector(
  currentUser,
  (state) => state?.role?.toUpperCase() === 'ADMIN'
);

export const handPosition = createSelector(
  currentUser,
  (state) => state?.studentPosition);

export const isPrivileged = createSelector(
  currentUser,
  (state) => state?.isPrivileged
);
export const isToasterAdmin = createSelector(
  currentUser,
  (state) => state?.isToasterAdmin
);


export const isMasterTeacher = createSelector(
  currentUser,
  (state) => state?.role?.toUpperCase() === 'MASTERTEACHER'
);

export const timeZone = createSelector(currentUser, (state) => state?.timeZone);
export const isDemoStudent = createSelector(
  currentUser,
  (state) => state?.isStudent && state?.isPrivileged
);
export const isSupperStudent = createSelector(
  isStudent,
  isPrivileged,
  (isStudent, isPrivileged) => (isStudent && isPrivileged) ?? false
);
export const selectUserProfile = createSelector(
  currentUser,
  (state) => state
);
export const selectUserLocalConfigs = createSelector(
  rootState,
  (state) => state.userLocalConfigs
);
export const selectUserDashboardRecordsSettings = createSelector(
  rootState,
  (state) => state.userLocalConfigs.dashboardRecordsSettings
);
export const selectTestGradeRecordsSettings = createSelector(
  rootState,
  (state) => state.userLocalConfigs.testGradeRecordsSettings
);

export const selectPracticeTestStudentWordSettings = createSelector(
  rootState,
  (state) => state.userLocalConfigs.practiceTestStudentWordSettings
);
export const allExpandedPracticeTestStudentWordSettings = createSelector(
  rootState,
  (state) => Object.values(state.userLocalConfigs.practiceTestStudentWordSettings).every(s => s)
);

export const allCollapsePracticeTestStudentWordSettings = createSelector(
  rootState,
  (state) => Object.values(state.userLocalConfigs.practiceTestStudentWordSettings).every(s => !s)
);

export const selectFluencyResultsSettings = createSelector(
  rootState,
  (state) => state.userLocalConfigs.fluencyResultsSettings
);

export const selectAssignmentsRequestSettings = createSelector(
  rootState,
  (state) => state.userLocalConfigs.assignmentRequest
);
export const selectTreeState = createSelector(
  rootState,
  (state) => state.userLocalConfigs.treeState
);
export const selectTreeStateFromMoveToDirectory = createSelector(
  rootState,
  (state) => state.userLocalConfigs.treeStateFromMoveToDirectory
);
export const defaultAssignmentDirectoryId = createSelector(currentUser, (state) => state?.defaultAssignmentDirectoryId);
export const defaultTestDirectoryId = createSelector(currentUser, (state) => state?.defaultTestDirectoryId);
export const defaultPracticeTestDirectoryId = createSelector(currentUser, (state) => state?.defaultPracticeTestDirectoryId);
export const getLocalTestDetails = createSelector(rootState, (state) => state.userLocalConfigs?.practiceTestDrawingPadLocalSettings);

export const getNotificationPermission = createSelector(rootState, (state) => state.notificationPermission);
export const getClipboardPermission = createSelector(rootState, (state) => state.clipboardPermission);

export const getPermissionAsked = createSelector(rootState, (state) => state.permissionAsked);

export const getTestDetailsByTestId = createSelector(getLocalTestDetails, (state, testId: number): {
  testDetails: IStudentTestDetails;
  testEndTime: Date;
} => state[testId]);

export const SelectCanUseStudentApp = createSelector(CanIUseState, (state) => state);

export const SelectCanRotateDevice = createSelector(CanRotateDeviceIUseState, (state) => state);

export const SelectCanFinalGradeDateRange = createSelector(CanFinalGradeDateRangeState, (state) => state);

export const SelectCanGradingResultsDateRange = createSelector(CanGradingResultsDateRangeState, (state) => state);

export const defaultDirectoryId = createSelector(defaultAssignmentDirectoryId, defaultTestDirectoryId, defaultPracticeTestDirectoryId, (defaultAssignmentDirectoryId, defaultTestDirectoryId, defaultPracticeTestDirectoryId, type: DirectoryType) => {
  if (defaultPracticeTestDirectoryId && defaultAssignmentDirectoryId && defaultTestDirectoryId && type) {
    switch (type) {
      case DirectoryType.PracticeTestDirectory:
        return defaultPracticeTestDirectoryId;
      case DirectoryType.TestDirectory:
        return defaultTestDirectoryId;
      case DirectoryType.AssignmentDirectory:
        return defaultAssignmentDirectoryId;
      default:
        return 0;
    }
  }
  return 0;
});


export interface IBaseLineChartData<Name, Value> {
  name: string;
  series: IBaseSeries<Name, Value>[];
}

export interface IBaseSeries<K, T> {
  name: K;
  value: T;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty && control?.dirty);

    return invalidCtrl || invalidParent;
  }
}

export class ConfirmPasswordStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.dirty);
    const invalidParent = !!(control?.parent?.hasError('notSame') && control?.parent?.dirty && control?.dirty);
    return invalidCtrl || invalidParent;
  }
}

export enum LayersName {
  recording = 'recording',
  drawing = 'drawing',
  background = 'background'
}

export enum GroupNames {
  backgroundColorGroup = 'backgroundColorGroup',
  mainDrawingGroup = 'mainDrawingGroup',
  recordingColorGroup = 'recordingColorGroup',
  recordingDrawingGroup = 'recordingDrawingGroup',

  backgroundLineGroup = 'backgroundLineGroup',
  recordingLineGroup = 'recordingLineGroup'
}

export enum ShapeNames {
  bgRect = 'bgRect',
  bgRectRec = 'bgRectRec',

  bgLine1 = 'bgLine1',
  bgLine2 = 'bgLine2',
  bgLine3 = 'bgLine3',
  bgLine1Rec = 'bgLine1Rec',
  bgLine2Rec = 'bgLine2Rec',
  bgLine3Rec = 'bgLine3Rec'
}

export enum ErrorEnum {
  PermissionDenied = 'Permission denied',
  ChunkLoadError = 'ChunkLoadError',
  ReadPermissionDenied = 'Read permission denied',
  RequestedDeviceNotFound = 'Requested device not found',
  NoValidDataOnClipboard = 'No valid data on clipboard',
  NotAllowedError = 'NotAllowedError:',
  Initialise = `'initialise'`,
  TypeErrorQuery = `'query@'`,
  GetIndexInParent = `'getIndexInParent'`,
  NotReadableError = 'NotReadableError:',
  CouldNotStartVideoSource = 'Could not start video source',
  CantFindVariableNotification = `Can't find variable: Notification`,
}

export type exceptionType = "window" | "business_rule"

export class SlackException {
  name: string = 'Not Available';
  description: string = "Not Available";
  type: exceptionType = "business_rule";
  userAgent: string = navigator.userAgent;
  url: string = location.href;
  componentName: string;
  functionName: string;
  userName: string;
  data: string;
  errorMessage: string;
  source: string;
  lineno: number;
  colno: number;
  teacherName: string;

  toString(): string {
    return `name : ${this.name ?? ''}\ndescription : ${this.description ?? ''}\ntype : ${this.type ?? ''}\nuserAgent : ${this.userAgent ?? ''}\nurl : ${this.url ?? ''}\ncomponentName : ${this.componentName ?? ''}\nfunctionName : ${this.functionName ?? ''}\nuserName : ${this.userName ?? ''}\ndata : ${this.data ?? ''}\nerrorMessage : ${this.errorMessage ?? ''}\nsource : ${this.source ?? ''}\nlineno : ${this.lineno ?? ''}\ncolno : ${this.colno ?? ''}`;
  }
}
