import {createUrl} from './base-url';

export const GeneralUrls = {
  getEnum: (baseUrl: string) => createUrl(baseUrl, 'General/getEnumList/:enumName'),
  getApiVersion: (baseUrl: string) => createUrl(baseUrl, 'General/getApiVersion'),
  getSound: (baseUrl: string) => createUrl(baseUrl, 'General/getSound?Word=:word'),
  sendSlackMessage: (baseUrl: string) => createUrl(baseUrl, 'Account/sendSlackMessage'),
  getAttemptsByIds: (baseUrl: string) => createUrl(baseUrl, 'General/getAttemptsByIds'),
  getLatestAppVersion:(baseUrl: string) => createUrl(baseUrl, 'General/getLatestAppVersion'),
  getBrandingDetails: (baseUrl: string) => createUrl(baseUrl, 'General/getBrandingDetails'),
  sendTestNotLoadedEmail: (baseUrl: string) => createUrl(baseUrl, 'Account/sendTestNotLoadedEmail')
};
