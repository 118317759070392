import {
  AssignmentSubTypes,
  AssignmentTypes,
  AssignmentWords,
  AudioTypesEnum,
  RecordingTypesEnum
} from '../assignment/assignment';
import {BaseFilterRequest, IBaseLineChartData, IdNameModel} from '../base/baseResponse';
import {StudentHandPosition} from '../student/student';

export class TestList {
  id: number;
  testName: string;
  startDate: Date;
  endDate: Date;
  assignmentId: number;
  assignmentName: string;
  assignmentType: AssignmentTypes;
  totalGroups: number;
  showResult?: boolean;
  isCurrentTest?: boolean;
  isArchived: boolean;
  closedTest: boolean;
  globleLink: string;
  groups: IGroupShortDetail[];
}

export class IGroupShortDetail extends IdNameModel {
  studentNames?: string;
  loading?: boolean;
  failed?: boolean;
}

export interface ITest {
  id: number;
  name: string;
  groups: number[];
  students: number[];
  assignmentId: number;
  assignmentName: string;
  startDate: Date | string;
  endDate: Date | string;
  audioType: string;
  recordingType: string;
  randomizeBehaviour: RandomizeBehaviourEnum;
  directoryId?: number;
  assignmentSubType: AssignmentSubTypes;
  assignmentType: AssignmentTypes;
  showWord?: boolean;
  showImageAsBackground?: boolean;
  showEquationWithAnswer?: boolean;
  showTimer?: boolean;
  totalRecordingAvailable?: number;
  totalWord?: number;
  testSlides: TestSlides[];
  testFeedbacks: TestSlides[];
}

export class TestSlides {
  testId: number;
  relationalId: number;
  sequence: number;
}


export interface ITestResultTestDetail {
  id: number;
  groups: number[];
  students: number[];
  startDate: Date;
  endDate: Date;
}

export interface IStartTestRequest {
  studentId: number;
  testDayId: number;
}

export interface IStudentTestDetails {
  testName: string;
  testId: number;
  testDayId: number;
  subType: AssignmentSubTypes;
  assignmentType: AssignmentTypes;
  testStatus: TestStatus;
  studentTestDayId: number;
  assignmentName: string;
  assignmentId: number;
  currentIntroSlideId: number;
  currentWordId: number;
  assignmentWords: AssignmentWords[];
  configurations: Partial<ITestDayConfig>;
  currentFeedbackQuestionId: number;
  teacherName : string;
}

export interface ITestConfigs {
  key: TestConfigs;
  value: boolean;
}

export enum TestConfigs {
  ShowWord = 'Show_Word',
}

export interface IStudentAttemptRequest {
  studentTestDayId: number;
  assignmentWordId: number;
  assignmentId: number;
  nextWordId: number | null;
  word?: string;
  answer?: string;
}

export interface ITestDayStudent {
  studentId: number;
  studentTestDayId: number;
  studentName: string;
  studentCode: string;
  totalAttemptedWord: number;
  status: string;
}

export interface ITestDay {
  testDayId: number;
  sequence: number;
  date: Date;
  isTestDay: boolean;
  isAttemptAvailable: boolean;
  isArchived: boolean;
  status: string;
  notAttemptedStudentCount: number;
}

export class TestDayStudentListRequest extends BaseFilterRequest {
  testDayId: number = 0;
}

export class TestIdStudentListRequest extends BaseFilterRequest {
  testId: number = 0;
}

export interface IStudentAttemptWord {
  assignmentWordId: number;
  word: string;
  answer: string;
}

export interface IStudentAttemptedRequest {
  assignmentWordId: number;
  studentTestDayId: number;
}

export interface IStudentWordsDetail {
  testId: number;
  studentTestDayId: number;
  studentName: string;
  day: Date;
  daySequence: number;
  isTestDay: boolean;
  testDayId: number;
  studentAttemptWords: IStudentAttemptWord[];
  letterFluency: number;
  wordFluency: number;
  accuracy: number;
}

export interface IStudentAttemptedWord {
  studentAttemptId: number;
  attemptImageURL: string;
  attemptVideoURL: string;
  word: string;
  answer: string;
  attemptData?: string[];
  loadingImage?: boolean;
  loadingAttemptData?: boolean;
  successAttemptData?: boolean;
  error?: boolean;
  showVideo?: boolean;
  durationInMinute: number;
}

export interface IMediaData {
  mediaInProcess: boolean;
  mediaFailed: boolean;
  mediaSuccess: boolean;
  noMediaAttached: boolean;
  mediaDataUrls: { [key: string]: string };
  mediaNote?: string;
}

export interface IReviewAttemptListRequest {
  AssignmentWordId: number;
  TestDayId: number;
  showAlreadyGraded: boolean;
}

export interface IReviewAttempt {
  attemptId: number;
  storageAttemptImageKey?: string;
  storageAttemptImageURL?: string;
  storageAttemptVideoURL?: string;
  isWrong: boolean;
  notes: string;
  // tagId: number;
  tagIds: number[];
  isShowNotes: boolean;
  isDemoAttempt: boolean;
  type: AssignmentTypes;
  word: string;
  answer: string;
  configue: ITestDayConfig;
  sounds: { [key: string]: string };
  failedLetters: ILetters[];
  showVideo: boolean;
  isReviewed: boolean;
  isVideoConverted: boolean;
  durationTimeString:string;
}

export interface ILetters {
  sequence: number;
  letter: string;
  isSuccess: boolean;
}

export interface IProgressMonitoringRequest {
  startDate: Date;
  endDate: Date;
  showDemoStudent: boolean;
}

export interface IProgressMonitoringResponse {
  testId: number;
  testName: string;
  totalWordCount: number;
  groupDashbordModels: IGroupDashboard[];
}

export interface IGroupDashboard {
  groupId: number;
  groupName: string;
  groupColor: string;
  testDayDashbordModels: ITestDayDashboard[];
}

export interface ITestDayDashboard {
  testDayId: number;
  isTestDay: boolean;
  testDayDate: Date;
  studentDatas: IStudentData[];
}

export interface IStudentData {
  studentId: number;
  studentName: string;
  totalAttemptedWord: number;
  totalReviewedWord: number;
  totalUnReviewedWord: number;
  failedAttempt: number;
  successAttempt: number;
}

export interface IStudentDataMap extends IStudentData {
  groupName: string;
  groupColor: string;
}

export interface IProgressMonitoringData {
  testId: number;
  testName: string;
  totalWordCount: number;
  isTestDay: boolean;
  testDayDate?: Date;
  studentData: IStudentDataMap[];
}

export interface IAssignmentChartDataRequest {
  assignments: number[];
  groups: number[];
  students: number[];
  startDate?: Date;
  endDate?: Date;
  type?: string;
  pageNo: number;
  pageSize: number;
}

export interface IFluencyChartDataRequest {
  // assignments: number[];
  // groups: number[];
  students: number[];
  startDate?: Date;
  endDate?: Date;
  type?: string;
  // pageNo: number;
  // pageSize: number;
}

export interface ITestTableDataRequest {
  tests: number[];
  groups: number[];
  students: number[];
  startDate?: Date;
  endDate?: Date;
  skip?: boolean; //ui useage
  type?: string;
  showUnattmpted: boolean;
}

export interface ITestTableData {
  id: string;
  studentId: number;
  studentName: string;
  assignmentId: number;
  assignmentName: string;
  totalAssignmentWordCount: number;
  testDayData: ITestDayChartData[];
  xAxisTicks?: number[];
}

export interface IAssignmentChartData {
  id: string;
  studentId: number;
  studentName: string;
  assignmentId: number;
  assignmentName: string;
  totalAssignmentWordCount: number;
  testDayData: ITestDayChartData[];
  xAxisTicks?: number[];
}

export interface IFluencyChartData {
  studentId: number;
  studentName: string;
  chartData: ITestDayChartData[];
}

export interface ITestDayChartData {
  name: string;
  isAttempted?: boolean;
  series: ISeriesElement[];
}

export interface ISeriesElement {
  name: string;
  value: number;
}

export interface ITestTableGroups {
  groupName: string;
  groupId: number;
  gradeBookStudentModels: IGradeBookStudentModels[];
}

export class IGradeBookStudentModels {
  studentName: string;
  studentId: number;
  gradeBookTestModels: IGradeBookTestModels[];
}

export class IGradeBookTestModel {
  testId: number;
  testName: string;
  assignmentType: string;
  gradeBookTestModels: IGradeBookTestModels[];
  gradeBookTestModel: { [key: number]: IGradeBookTestModels }
}

export interface IGradeBookTestModels {
  id: number;
  testId?: number;
  name: string;
  isGraded: boolean;
  isAttempted: boolean;
  result: number | null;
  assignmentType: string;
  totalWords: number;
  totalSuccessCount: number;
  notCompletedWords : number;
  inCorrectWords: number;
  completedResult: number;
}

export interface ITestTable {
  tableData: ITestTableGroups[];
  tableHeadings: IdNameModel[];
}

export interface ITestGradeData {
  testId: number;
  testName: string;
  assignmentType: string;
  headers: IdNameModel[];
  data: IGradeBookStudentModels[];
}

export interface ITestGradeDataByStudent {
  studentName: string;
  studentId: number;
  headers: IdNameModel[];
  data: IGradeBookTestModel[];
}


export interface ITestDayForConfig {
  testDayId: number;
  sequence: number;
  isTestDay: boolean;
  date: Date,
  config: ITestDayConfig[];
  readonly?: boolean;
}

export interface ITestDayConfig {
  id: number;
  groupId: number;
  groupName: string;
  showWord: boolean;
  // showImageAsBackground: boolean;
  showEquationWithAnswer: boolean;
  randomizeBehaviour: RandomizeBehaviourEnum;
  audioType: AudioTypesEnum;
  recordingType: RecordingTypesEnum;
  assignmentType: AssignmentTypes;
  operandStartLimit: number;
  operandEndLimit: number;
  operators: string | string[];
  totalTimeInMinute: number;
  showTimer: boolean;
  showIntroductionSlide: boolean;
  showFeedbackSlide: boolean;
  hasIntroductionSlide: boolean;
  hasFeedbackQuestion: boolean;
  studentPosition: StudentHandPosition;
  operatorConfigs: OperatorConfig[];
}

export class OperatorConfig {
  operandStartLimit: number;
  operandEndLimit: number;
  operator: string;
  isAllowNegative: boolean;
  isAllowDecimal: boolean;
}

export interface IUpdateTestDayConfigRequest {
  testDayConfigList: ITestDayConfig[];
}

export interface IStudentFluencyChartDetails {
  studentId: number;
  studentName: string;
  mathFluency: {
    fluencyDetails: IFluencyTestDetails[];
    wordFluencyData: IBaseLineChartData<string, number>[];
    letterFluencyData: IBaseLineChartData<string, number>[];
    accuracyData: IBaseLineChartData<string, number>[];
    colorScheme?: string[];
  };
  spellingFluency: {
    fluencyDetails: IFluencyTestDetails[];
    wordFluencyData: IBaseLineChartData<string, number>[];
    letterFluencyData: IBaseLineChartData<string, number>[];
    accuracyData: IBaseLineChartData<string, number>[];
    colorScheme?: string[];
  };
}

export interface IFluencyTestDetails {
  testId: number;
  testName: string;
  assignmentType: string;
  values: IFluencyDetailsValues[];
}

export interface IFluencyDetails {
  testId: number;
  testName: string;
  testDayDate: Date;
  assignmentType: string;
  values: IFluencyDetailsValues;
}

export interface IFluencyDetailsValues {
  testId: number;
  accuracyValue: number;
  fluencyValue: number;
  wordFluencyValue: number;
  totalTimeInMinute: number;
  testDate?: string;
}

export enum TestStatus {
  NEW = 'NEW',
  COMPLETE = 'COMPLETE',
  RESUME = 'RESUME',
  COMPLETED = 'COMPLETED',
}

export enum RandomizeBehaviourEnum {
  Random = 'Random',
  Sequential = 'Sequential',
}

export interface IGetPracticeTestWordAttempts {
  testId: number;
  studentId: number;
}

export interface IPracticeTestWordAttempts {
  assignmentWordId: number;
  word: string;
  answer: string;
  assignmentType: AssignmentTypes;
  attempts: IPracticeTestAttempts[];
}

export interface IPracticeTestAttempts {
  attemptId: number;
  attemptImageUrl: string;
  attemptVideoUrl: string;
  attemptDateTime: Date;
  attemptDateTimeLocal: Date;
  isSkipped: boolean;
}

export interface IChangeTestDir {
  directoryId: number;
  testId: number;
}

export class ITestDetailsModel {
  testId: number;
  isPracticeTest: boolean;
}


export interface IPendingGradingTest {
  testDayId: number;
  testId: number;
  testName: string;
  sequence: number;
  isTestDay: boolean;
  noOfAttemptedStudents: number;
  assignmentType: AssignmentTypes;
  date:Date;
}

export interface ITestIntroSlide {
  id: number;
  title: string;
  videoKey: string;
  videoURL: string;
  content: string;
  audioKey: string;
  audioURL: string;
  storagePath: string;
  slideType: string;
  audioType: string;
  teacherId: number;
  previewURL: string;
  sequenceNo: number;
}

export interface ICompleteTestIntroSlideReq {
  slideId: number;
  studentTestDayId: number;
}

export interface ITestFeedbackQuestion {
  id: number;
  teacherId: number;
  title: string;
  optionType: string;
  audioType: string;
  audioKey: string;
  audioURL: string;
  sequence: number;
  dataSources: ITestFeedbackSlideDataSource[];
  isReview: boolean;
}

export interface ITestFeedbackSlideDataSource {
  id: number;
  sequence: number;
  value: number;
  text: string;
  imageUrl: string;
  studentChoiceCount: number;
  studentNames: string[];
}

export interface ISaveAssignmentFeedbackReq {
  studentTestDayId: number;
  testFeedbackId: number;
  selectedOptionId: number;
}

export class DemoLinkListModel {
  id: number;
  teacherId: number;
  teacherName: string;
  link: string;
  testId: number;
  testName: string;
  copyDate: Date;
}

export class StudentTestResultRequest {
  studentId: number;
  testDayId: number;
}

export class StudentTestResultResponse {
  studentId: number;
  studentName: string;
  testId: number;
  testName: string;
  result: number;
  config: ITestDayConfig[];
  studentTestResultWords: StudentTestResultWord[];
  fluencyDetail: FluencyDetail;
}

export class FluencyDetail{
  testDate: string;
  testName: string;
  testId: number;
  fluencyValue: number;
  wordFluencyValue: number;
  accuracyValue: number;
  totalTimeInMinute: number;
  assignmentType: string;
}

export class StudentTestResultWord {
  assignmentWordId: number;
  storageAttemptImageURL: string;
  storageAttemptVideoURL: string;
  failedLetters: ILetters[];
  groupId: number;
  sounds: { [key: string]: string };
  type: AssignmentTypes;
  word: string;
  answer: string;
  durationInMinute: number;
  attemptId: number;
  studentTestDayId: number;
  storageAttemptImageKey?: string;
  storageAttemptVideoKey?: string;
  isWrong: boolean;
  isDemoAttempt: boolean;
  notes: string;
  showVideo?: boolean;
  isVideoConverted: boolean;
  imageUrl: string
  tags: string[];
  isAttemptAvailable : boolean;
}

export class TestBasicDetail {
  id: number;
  assignmentId: number;
  assignmentName: string;
}

export interface IUnsavedAttemptDetail {
  id: number;
  studentName: string;
  word: string;
  teacherId: number;
  assignmentWordId: number;
  assignmentName: string;
  studentTestDayId: number;
  testName: string;
  teacherName: string;
  image?: string;
  blob?: Blob;
  startTimeToken: number;
  isAnswerImg: boolean;
}

export class AttemptsPaginatedRequest extends BaseFilterRequest {
  status: string[] = ['VideoConversionInitiated', 'VideoConversionInProcess'];
}

export class AttemptsPaginated {
  id: number;
  studentName: string;
  word: string;
  assignmentName: string;
  testName: string;
  studentTestDayId: number;
  teacherName: string;
  email: string;
  videoConversionStatus: string;
  storageAttemptImageKey: string;
  storageAttemptImageUrl: string;
  assignmentWordId: number;
  createDate: Date;
}
