import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {
  AssignmentTypes,
  AttemptsPaginated,
  AttemptsPaginatedRequest,
  AudioTypesEnum,
  BaseResponse,
  DemoLinkListModel,
  DirectoryPaginatedRequest,
  IAssignmentChartData,
  IAssignmentChartDataRequest,
  IChangeTestDir,
  ICompleteTestIntroSlideReq,
  IdNameModel,
  IFluencyChartData,
  IFluencyChartDataRequest,
  IGradeBookStudentModels,
  IGradeBookTestModels,
  IPendingGradingTest,
  IProgressMonitoringData,
  IProgressMonitoringRequest,
  IProgressMonitoringResponse,
  IReviewAttempt,
  IReviewAttemptListRequest,
  ISaveAssignmentFeedbackReq,
  ISeriesElement,
  IStudentAttemptedRequest,
  IStudentAttemptedWord,
  IStudentAttemptRequest,
  IStudentDataMap,
  IStudentFluencyChartDetails,
  IStudentTestDetails,
  IStudentWordsDetail,
  ITest,
  ITestDay,
  ITestDayConfig,
  ITestDayForConfig,
  ITestDayStudent,
  ITestDetailsModel,
  ITestFeedbackQuestion,
  ITestGradeData,
  ITestGradeDataByStudent,
  ITestIntroSlide,
  ITestResultTestDetail,
  ITestTable,
  ITestTableDataRequest,
  ITestTableGroups,
  IUpdateTestDayConfigRequest,
  PaginatedResponse,
  RecordingTypesEnum,
  StudentTestResultRequest,
  StudentTestResultResponse,
  TestBasicDetail,
  TestDayStudentListRequest,
  TestList
} from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TestUrls} from './apiUrls/test-urls';
import {cloneDeep, max, maxBy, uniqBy} from 'lodash-es';
import {hashCode} from '@mptl/web/utilities';


declare var window: any;

@Injectable()
export class TestService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  public getAllTestList(
    request: DirectoryPaginatedRequest
  ): Observable<BaseResponse<PaginatedResponse<TestList[]>, DirectoryPaginatedRequest>> {
    return this.http.post(TestUrls.getAllTestList(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<TestList[]>,
          DirectoryPaginatedRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public clearAllDemoLinks(): Observable<BaseResponse<string, string>> {
    return this.http.get(TestUrls.clearDemoTestLinks(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<string, string> = res;
        return data;
      })
    );
  }

  public createTest(request: ITest): Observable<BaseResponse<number, ITest>> {
    return this.http.post(TestUrls.createTest(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<number, ITest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public updateTest(request: ITest): Observable<BaseResponse<string, ITest>> {
    return this.http.post(TestUrls.updateTest(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ITest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getTestById(request: number): Observable<BaseResponse<ITest, number>> {
    return this.http
      .get(
        TestUrls.getTestById(this.baseUrl).replace(
          ':testId',
          request?.toString()
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<ITest, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public deleteTest(request: number): Observable<BaseResponse<string, number>> {
    return this.http
      .delete(
        TestUrls.deleteTest(this.baseUrl).replace(
          ':testId',
          request?.toString()
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public copyTestLink(request: number): Observable<BaseResponse<string, number>> {
    return this.http
      .get(
        TestUrls.copyTestLink(this.baseUrl).replace(
          ':testId',
          request?.toString()
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public startStudentTest(
    testDayId: number
  ): Observable<BaseResponse<IStudentTestDetails, number>> {
    return this.http
      .post(TestUrls.startStudentTest(this.baseUrl), {testDayId})
      .pipe(
        map((res: BaseResponse<IStudentTestDetails, number>) => {
          res.request = testDayId;
          return res;
        })
      );
  }

  public saveStudentTestWordAttempt(
    request: IStudentAttemptRequest
  ): Observable<BaseResponse<number, IStudentAttemptRequest>> {
    return this.http
      .post(TestUrls.saveStudentTestWordAttempt(this.baseUrl), request)
      .pipe(
        map((res: BaseResponse<number, IStudentAttemptRequest>) => {
          res.request = request;
          return res;
        })
      );
  }

  public getTestDayListByTestId(
    request: number
  ): Observable<BaseResponse<ITestDay[], number>> {
    return this.http
      .get(
        TestUrls.getTestDayListByTestId(this.baseUrl).replace(
          ':TestId',
          request?.toString()
        )
      )
      .pipe(
        map((res: BaseResponse<ITestDay[], number>) => {
          res.request = request;
          return res;
        })
      );
  }


  public getTestDayStudents(
    request: TestDayStudentListRequest
  ): Observable<BaseResponse<PaginatedResponse<ITestDayStudent[]>,
    TestDayStudentListRequest>> {
    return this.http
      .post(TestUrls.getTestDayResult(this.baseUrl), request)
      .pipe(
        map(
          (
            res: BaseResponse<PaginatedResponse<ITestDayStudent[]>,
              TestDayStudentListRequest>
          ) => {
            res.request = request;
            return res;
          }
        )
      );
  }

  public getStudentTestWordsById(
    StudentTestDayId: number
  ): Observable<BaseResponse<IStudentWordsDetail, number>> {
    return this.http
      .get(
        TestUrls.getStudentTestDayDetailById(this.baseUrl).replace(
          ':StudentTestDayId',
          StudentTestDayId?.toString()
        )
      )
      .pipe(
        map((res: BaseResponse<IStudentWordsDetail, number>) => {
          res.request = StudentTestDayId;
          return res;
        })
      );
  }

  public getStudentTestWordAttemptDetails(
    request: IStudentAttemptedRequest
  ): Observable<BaseResponse<IStudentAttemptedWord[], IStudentAttemptedRequest>> {
    return this.http
      .get(
        TestUrls.getStudentTestWordAttemptDetails(this.baseUrl)
          .replace(':AssignmentWordId', request.assignmentWordId?.toString())
          .replace(':StudentTestDayId', request.studentTestDayId?.toString())
      )
      .pipe(
        map(
          (
            res: BaseResponse<IStudentAttemptedWord[], IStudentAttemptedRequest>
          ) => {
            res.request = request;
            res.data = res.data?.map((s) => ({...s, showVideo: false}));
            return res;
          }
        )
      );
  }

  public getStudentTestWordAttemptData(
    request: number
  ): Observable<BaseResponse<string[], number>> {
    return this.http
      .get(
        TestUrls.getStudentTestWordAttemptData(this.baseUrl).replace(
          ':AttemptId',
          request.toString()
        )
      )
      .pipe(
        map((res: BaseResponse<string[], number>) => {
          res.request = request;
          return res;
        })
      );
  }

  public saveTempAttempt(
    attemptData: string
  ): Observable<BaseResponse<string, string>> {
    return this.http
      .post(TestUrls.saveTempAttempt(this.baseUrl), {attemptData})
      .pipe(
        map((res: BaseResponse<string, string>) => {
          res.request = attemptData;
          return res;
        })
      );
  }

  public getAttemptsForReview(
    request: IReviewAttemptListRequest
  ): Observable<BaseResponse<IReviewAttempt[], IReviewAttemptListRequest>> {
    return this.http
      .get(
        TestUrls.getStudentTestDayWordAttempt(this.baseUrl)
          .replace(':AssignmentWordId', request.AssignmentWordId?.toString())
          .replace(':TestDayId', request.TestDayId?.toString())
          .replace(':ShowAlreadyGraded', request.showAlreadyGraded?.toString())
      )
      .pipe(
        map(
          (res: BaseResponse<IReviewAttempt[], IReviewAttemptListRequest>) => {
            res.request = request;
            res.data = res.data.map((word) => {
              const audioType = word.configue.audioType;
              const recordingType = word?.configue?.recordingType;
              if (
                word.sounds &&
                Object.keys(word.sounds)?.length &&
                audioType
              ) {
                switch (audioType) {
                  case AudioTypesEnum.NONE: {
                    word.sounds = {};
                    break;
                  }
                  case AudioTypesEnum.TEACHER_RECORDING: {
                    word.sounds = {
                      [word.word?.toLowerCase() + '_tec']:
                        word.sounds[word.word?.toLowerCase()]
                    };
                    break;
                  }
                  case AudioTypesEnum.AMAZONE_POLLY: {
                    switch (recordingType) {
                      case RecordingTypesEnum.SAY_WORD: {
                        if (word.type === AssignmentTypes.MATH) {
                          word.word
                            .split('')
                            .filter((s) => {
                              return ['+', '-', '*', '/', 'X', '÷'].indexOf(s) > -1;
                            })
                            .forEach((symbol: string) => {
                              switch (symbol) {
                                case '+': {
                                  word.sounds[symbol] =
                                    window.location.protocol +
                                    '//' +
                                    window.location.host +
                                    '/assets/sounds/math/' +
                                    'PLUS.mp3';
                                  break;
                                }
                                case '-': {
                                  word.sounds[symbol] =
                                    window.location.protocol +
                                    '//' +
                                    window.location.host +
                                    '/assets/sounds/math/' +
                                    'MINUS.mp3';
                                  break;
                                }
                                case 'X':
                                case '*': {
                                  word.sounds[symbol] =
                                    window.location.protocol +
                                    '//' +
                                    window.location.host +
                                    '/assets/sounds/math/' +
                                    'MULTIPLY_BY.mp3';
                                  break;
                                }
                                case '÷':
                                case '/': {
                                  word.sounds[symbol] =
                                    window.location.protocol +
                                    '//' +
                                    window.location.host +
                                    '/assets/sounds/math/' +
                                    'DIVIDE_BY.mp3';
                                  break;
                                }
                              }
                            });
                        }
                        break;
                      }
                      case RecordingTypesEnum.SPELL_IT: {
                        word.word.split('').forEach((s) => {
                          word.sounds[s] =
                            window.location.protocol +
                            '//' +
                            window.location.host +
                            '/assets/sounds/abcd/' +
                            s.toUpperCase() +
                            '.mp3';
                        });
                        delete word.sounds[word.word?.toLowerCase()];
                        break;
                      }
                      case RecordingTypesEnum.SAY_WORD_THEN_SPELL_IT:
                      case RecordingTypesEnum.SPELL_IT_THEN_SAY_WORD: {
                        word.word.split('').forEach((s) => {
                          word.sounds[s] =
                            window.location.protocol +
                            '//' +
                            window.location.host +
                            '/assets/sounds/abcd/' +
                            s.toUpperCase() +
                            '.mp3';
                        });
                        break;
                      }
                    }
                    break;
                  }
                }
              }
              return word;
            });

            return res;
          }
        )
      );
  }

  public getFeedbacjAttemptsForReview(
    testDayId: number
  ): Observable<BaseResponse<ITestFeedbackQuestion[], number>> {
    return this.http
      .get(
        TestUrls.getTestFeedbackResultByTestDayId(this.baseUrl)
          .replace(':TestDayId', testDayId?.toString())
      )
      .pipe(
        map(
          (res: BaseResponse<ITestFeedbackQuestion[], number>) => {
            return res;
          }
        )
      );
  }

  public saveWordAttemptReview(
    attempts: IReviewAttempt[]
  ): Observable<BaseResponse<string, IReviewAttempt[]>> {
    let cloneAttempts = cloneDeep(attempts);
    if (cloneAttempts.length) {
      cloneAttempts.forEach((item) => {
        if (!!item.storageAttemptImageKey) delete item.storageAttemptImageKey;
        if (!!item.storageAttemptImageURL) delete item.storageAttemptImageURL;
      });
    }
    return this.http
      .post(TestUrls.saveWordAttemptReview(this.baseUrl), {
        attempts: cloneAttempts
      })
      .pipe(
        map((res: BaseResponse<string, IReviewAttempt[]>) => {
          res.request = attempts;
          return res;
        })
      );
  }

  public getTestAssignmentChartData(
    request: IAssignmentChartDataRequest
  ): Observable<BaseResponse<PaginatedResponse<IAssignmentChartData[]>,
    IAssignmentChartDataRequest>> {
    return this.http
      .post(TestUrls.getTestAssignmentChartData(this.baseUrl), request)
      .pipe(
        map(
          (
            res: BaseResponse<PaginatedResponse<IAssignmentChartData[]>,
              IAssignmentChartDataRequest>
          ) => {
            res.request = request;
            if (res.data.data?.length) {
              res.data.data = res.data.data.map((s) => ({
                ...s,
                xAxisTicks: Array.from({
                  length:
                    (max(
                      s.testDayData.map(
                        (data) =>
                          maxBy<ISeriesElement>(data.series, 'value')?.value
                      )
                    ) ?? 0) + 1
                }).map((x, i) => (x = i))
              }));
            }
            return res;
          }
        )
      );
  }

  public getFluencyChartData(
    request: IFluencyChartDataRequest
  ): Observable<BaseResponse<IFluencyChartData[], IFluencyChartDataRequest>> {
    return this.http
      .post(TestUrls.getStudentFluencyChartDetails(this.baseUrl), request)
      .pipe(
        map((res) => {
          res.request = request;
          return res;
        })
      );
  }

  public getStudentFluencyChartDetails(
    request: IFluencyChartDataRequest
  ): Observable<BaseResponse<IStudentFluencyChartDetails[], IFluencyChartDataRequest>> {
    return this.http
      .post(TestUrls.getStudentFluencyChartDetails(this.baseUrl), request)
      .pipe(
        map((res: BaseResponse<IStudentFluencyChartDetails[], IFluencyChartDataRequest>) => {
          res.data = res.data.map(data => {
            return {
              ...data,
              spellingFluency: data.spellingFluency ? {
                ...data.spellingFluency,
                colorScheme: data.spellingFluency.wordFluencyData?.map(s => {
                  return hashCode(s.name);
                })
              } : data.spellingFluency,
              mathFluency: data.mathFluency ? {
                ...data.mathFluency,
                colorScheme: data.mathFluency.wordFluencyData?.map(s => {
                  return hashCode(s.name);
                })
              } : data.mathFluency
            };
          });
          res.request = request;

          return res;
        })
      );
  }

  public getTestDropdownList(): Observable<BaseResponse<IdNameModel[], string>> {
    return this.http.get(TestUrls.getTestDropdownList(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<IdNameModel[], string> = res;
        return data;
      })
    );
  }

  public getStudentGradeBook(
    request: ITestTableDataRequest
  ): Observable<BaseResponse<ITestTable, ITestTableDataRequest>> {
    return this.http
      .post(TestUrls.getStudentGradeBook(this.baseUrl), request)
      .pipe(
        map((res: BaseResponse<ITestTableGroups[], ITestTableDataRequest>) => {
          const data: BaseResponse<ITestTable, ITestTableDataRequest> =
            new BaseResponse<ITestTable, ITestTableDataRequest>();
          data.request = request;
          if (res.data?.length) {
            const headings: IdNameModel[] = uniqBy(
              res.data
                .reduce(
                  (s, value) => s.concat(value.gradeBookStudentModels),
                  [] as IGradeBookStudentModels[]
                )
                .reduce(
                  (s, value) => s.concat(value.gradeBookTestModels),
                  [] as IGradeBookTestModels[]
                )
                .map((s) => ({
                  id: s.id,
                  name: s.name
                })),
              'id'
            );
            data.data = {
              tableHeadings: headings,
              tableData: res.data.map((data) => {
                data.gradeBookStudentModels.map((studentData) => {
                  studentData.gradeBookTestModels = headings.map((head) => {
                    const record = studentData.gradeBookTestModels.find(
                      (s) => s.id === head.id
                    );
                    if (record) {
                      return record;
                    } else {
                      return {
                        id: head.id,
                        name: head.name,
                        isAttempted: true,
                        isGraded: true,
                        result: null,
                        assignmentType: null,
                        totalWords: 0,
                        notCompletedWords: 0,
                        inCorrectWords: 0,
                        totalSuccessCount: 0,
                        completedResult: 0,
                        notCompletedResult: 0,
                        notAttemptedWords: 0
                      };
                    }
                  });
                  return studentData;
                });
                return data;
              })
            };
          } else {
            data.data = {
              tableHeadings: [],
              tableData: []
            };
          }
          return data;
        })
      );
  }

  public getTestGradeData(
    request: ITestTableDataRequest
  ): Observable<BaseResponse<ITestGradeData[], ITestTableDataRequest>> {
    return this.http
      .post(TestUrls.GetTestGradeData(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<ITestGradeData[], ITestTableDataRequest> =
            res;
          data.request = request;
          return data;
        })
      );
  }

  public GetTestGradeDataByGroupOfStudent (request:ITestTableDataRequest):
  Observable<BaseResponse<ITestGradeDataByStudent[], ITestTableDataRequest>>{
    return this.http
    .post(TestUrls.GetTestGradeDataByGroupOfStudent(this.baseUrl), request)
    .pipe(
      map((res) => {
        const data: BaseResponse<ITestGradeDataByStudent[], ITestTableDataRequest> =
          res;
        data.request = request;
        return data;
      })
    );
  }





  public getTestDayConfigurations(
    request: number
  ): Observable<BaseResponse<ITestDayConfig[], number>> {
    return this.http
      .get(
        TestUrls.getTestDayConfigurations(this.baseUrl) +
        `?TestDayId=${request?.toString()}`
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<ITestDayConfig[], number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public getAllTestDayConfigurations(
    request: number
  ): Observable<BaseResponse<ITestDayForConfig[], number>> {
    return this.http
      .get(TestUrls.getAllTestDayConfigurations(this.baseUrl).replace(':id', request.toString()))
      .pipe(
        map((res) => {
          const data: BaseResponse<ITestDayForConfig[], number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public updateTestDayConfigurations(
    request: IUpdateTestDayConfigRequest
  ): Observable<BaseResponse<string, IUpdateTestDayConfigRequest>> {
    return this.http
      .post(TestUrls.updateTestDayConfigurations(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, IUpdateTestDayConfigRequest> = res;
          data.request = request;
          return data;
        })
      );
  }

  public generateDummyAttemptOnTestDay(
    request: number
  ): Observable<BaseResponse<string, number>> {
    return this.http
      .get(
        TestUrls.generateDummyAttemptOnTestDay(this.baseUrl).replace(
          ':TestDayId',
          request?.toString()
        ),
        request
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public generateDummyGradingOnTestDay(
    request: number
  ): Observable<BaseResponse<string, number>> {
    return this.http
      .get(
        TestUrls.generateDummyGradingOnTestDay(this.baseUrl).replace(
          ':TestDayId',
          request?.toString()
        ),
        request
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public testDetailForTestResults(
    request: number
  ): Observable<BaseResponse<ITestResultTestDetail, number>> {
    return this.http
      .get(
        TestUrls.testdetailForTestResults(this.baseUrl).replace(
          ':TestId',
          request?.toString()
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<ITestResultTestDetail, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public getTestDashboardData(
    request: IProgressMonitoringRequest
  ): Observable<BaseResponse<IProgressMonitoringData[], IProgressMonitoringRequest>> {
    return this.http
      .post(TestUrls.getTestDashboardData(this.baseUrl), {...request})
      .pipe(
        map((res: BaseResponse<IProgressMonitoringResponse[], IProgressMonitoringRequest>) => {
          const data: BaseResponse<IProgressMonitoringData[], IProgressMonitoringRequest> =
            new BaseResponse<IProgressMonitoringData[], IProgressMonitoringRequest>();
          data.request = request;
          data.status = res.status;
          data.errors = res.errors;
          data.queryString = res.queryString;
          data.hasError = res.hasError;
          if (!data.hasError) {
            data.data = res.data.map((response) => {
              const dataRecord: IProgressMonitoringData = {
                testId: response.testId,
                isTestDay: false,
                studentData: [],
                testName: response.testName,
                totalWordCount: response.totalWordCount
              };
              const studentData: IStudentDataMap[] = [];
              response.groupDashbordModels?.forEach((group) => {
                dataRecord.isTestDay = group.testDayDashbordModels[0].isTestDay;
                dataRecord.testDayDate =
                  group.testDayDashbordModels[0].testDayDate;
                group.testDayDashbordModels[0].studentDatas?.forEach(
                  (student) => {
                    const studentMap: IStudentDataMap = {
                      ...student,
                      groupColor: group.groupColor,
                      groupName: group.groupName
                    };
                    studentData.push(studentMap);
                  }
                );
              });
              dataRecord.studentData = studentData;
              return dataRecord;
            });
          }
          return data;
        })
      );
  }

  public createPracticeTestFromTest(request: Partial<ITest>): Observable<BaseResponse<string, Partial<ITest>>> {
    return this.http.post(TestUrls.createPracticeTestFromTest(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, Partial<ITest>> = res;
        data.request = request;
        return data;
      })
    );
  }

  public changeTestDirectory(request: IChangeTestDir): Observable<BaseResponse<string, IChangeTestDir>> {
    return this.http.post(TestUrls.changeTestDirectory(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, IChangeTestDir> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getTestDetailsFromEncryptedToken(code: string): Observable<BaseResponse<ITestDetailsModel, string>> {
    return this.http.post(TestUrls.getTestDetailsFromEncryptedToken(this.baseUrl), {code}).pipe(
      map((res) => {
        const data: BaseResponse<ITestDetailsModel, string> = res;
        data.request = code;
        return data;
      })
    );
  }

  public getPendingForGradingTests(): Observable<BaseResponse<IPendingGradingTest[], any>> {
    return this.http.get(TestUrls.getPendingTestsForGrading(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<IPendingGradingTest[], any> = res;
        return data;
      })
    );
  }

  public getIntroSlidesForTest(testId: number): Observable<BaseResponse<ITestIntroSlide[], any>> {
    return this.http.get(TestUrls.getIntroSlidesForTest(this.baseUrl).replace(':testId', testId?.toString())).pipe(
      map((res) => {
        const data: BaseResponse<ITestIntroSlide[], any> = res;
        return data;
      })
    );
  }

  public completeTestIntroSlide(request: ICompleteTestIntroSlideReq): Observable<BaseResponse<string, ICompleteTestIntroSlideReq>> {
    return this.http.post(TestUrls.completeTestIntroSlide(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ICompleteTestIntroSlideReq> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getFeedbackQuestionsForTest(testId: number): Observable<BaseResponse<ITestFeedbackQuestion[], any>> {
    return this.http.get(TestUrls.getFeedbackQuestionsForTest(this.baseUrl).replace(':testId', testId?.toString())).pipe(
      map((res) => {
        const data: BaseResponse<ITestFeedbackQuestion[], any> = res;
        return data;
      })
    );
  }

  public saveAssignmentFeedback(request: ISaveAssignmentFeedbackReq): Observable<BaseResponse<string, ISaveAssignmentFeedbackReq>> {
    return this.http.post(TestUrls.saveAssignmentFeedback(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ISaveAssignmentFeedbackReq> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getStudentTestResultByStudentId(request: StudentTestResultRequest): Observable<BaseResponse<StudentTestResultResponse, StudentTestResultRequest>> {
    return this.http.post(TestUrls.getStudentTestResultByStudentId(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<StudentTestResultResponse, StudentTestResultRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getAllTestDemoLinks(): Observable<BaseResponse<DemoLinkListModel[], string>> {
    return this.http
      .get(
        TestUrls.getAllDemoLinkList(this.baseUrl)
      )
      .pipe(
        map((res: BaseResponse<DemoLinkListModel[], string>) => {
          return res;
        })
      );
  }

  public getTestDayFeedbackResultByStudentId(request: StudentTestResultRequest): Observable<BaseResponse<ITestFeedbackQuestion[], StudentTestResultRequest>> {
    return this.http.post(TestUrls.getTestDayFeedbackResultByStudentId(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<ITestFeedbackQuestion[], StudentTestResultRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getTestBasicDetail(testDayId: number): Observable<BaseResponse<TestBasicDetail, number>> {
    return this.http.get(TestUrls.getTestBasicDetail(this.baseUrl).replace(':TestDayId', testDayId?.toString())).pipe(
      map((res) => {
        const data: BaseResponse<TestBasicDetail, number> = res;
        return data;
      })
    );
  }

  public getWordNotAttemptStudentsList(request: {testDayId: number; assignmentWordId: number }): Observable<BaseResponse<IdNameModel[], number>> {
    return this.http.get(TestUrls.getWordNotAttemptStudents(this.baseUrl).replace(':AssignmentWordId', request.assignmentWordId?.toString())
      .replace(':TestDayId', request.testDayId?.toString())).pipe(
      map((res) => {
        const data: BaseResponse<IdNameModel[], number> = res;
        return data;
      })
    );
  }
  public getAllAttemptsPaginated(request: AttemptsPaginatedRequest): Observable<BaseResponse<PaginatedResponse<AttemptsPaginated[]>, AttemptsPaginatedRequest>> {
    return this.http.post(TestUrls.getAllAttemptsPaginated(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<AttemptsPaginated[]>, AttemptsPaginatedRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public removeAttemptFromVideoConversionList(request: number): Observable<BaseResponse<string, number>> {
    return this.http.get(TestUrls.removeAttemptFromVideoConversionList(this.baseUrl,request)).pipe(
      map((res) => {
        const data: BaseResponse<string, number> = res;
        data.request = request;
        return data;
      })
    );
  }


}
