import {Injectable} from "@angular/core";
import * as localforage from "localforage";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class AttemptDbService {
  attemptDbForage: LocalForage;
  isReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.attemptDbForage = localforage.createInstance({
      name: "AttemptDB",
      storeName: "StudentAttempts",
      description: "stored attempts of students",
      version: 1,
      driver: localforage.INDEXEDDB,
      size: 1000000000
    });
    this.attemptDbForage.ready().then(() => {
      this.isReady.next(true);
    });
  }

  saveAttemptImage(attemptId: number, dataURI: string, timeToken: number) {
    return this.attemptDbForage.setItem(`${attemptId}_${timeToken}_image`, dataURI);
  }

  deleteAttemptImage(attemptId: number, timeToken: number) {
    return this.attemptDbForage.removeItem(`${attemptId}_${timeToken}_image`);
  }

  saveAttemptBlob(attemptId: number, blob: Blob) {
    return this.attemptDbForage.setItem(`${attemptId}_blob`, blob);
  }

  deleteAttemptBlob(attemptId: number) {
    return this.attemptDbForage.removeItem(`${attemptId}_blob`);
  }


  saveAttemptAnswerImage(attemptId: number, answerURI: string, timeToken: number) {
    return this.attemptDbForage.setItem(`${attemptId}_${timeToken}_image_clean`, answerURI);
  }
  deleteAttemptAnswerImage(attemptId: number, timeToken: number) {
    return this.attemptDbForage.removeItem(`${attemptId}_${timeToken}_image_clean`);
  }


  async getAllAttempts() {
    const keys = await this.attemptDbForage.keys();
    return Promise.all(keys?.map(s => this.attemptDbForage.getItem<string | Blob>(s).then(data => ({
      key: s,
      value: data
    }))));
  }

  deleteInstance() {
    return this.attemptDbForage.dropInstance()
  }
}
