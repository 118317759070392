import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {
  AddRegistrationRequests,
  AddRequestForDemo,
  BaseFilterRequest,
  BaseResponse,
  CertificateDetailModle,
  CloneTeacherRequest,
  CreateUser,
  IssueCertificateModel,
  ISubmitInterest,
  Login,
  LoginResponse,
  PaginatedResponse,
  RegistrationRequest,
  RegistrationRequestById,
  UserProfile
} from '@mptl/models';
import {Observable} from 'rxjs';
import {AuthUrls} from './apiUrls/auth-urls';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  getAllUserList(
    filterRequest: BaseFilterRequest
  ): Observable<BaseResponse<PaginatedResponse<UserProfile[]>, BaseFilterRequest>> {
    return this.http
      .post(AuthUrls.getAllUserList(this.baseUrl), filterRequest)
      .pipe(
        map((res) => {
          const data: BaseResponse<PaginatedResponse<UserProfile[]>,
            BaseFilterRequest> = res;
          data.request = filterRequest;
          return data;
        })
      );
  }

  removeUser(
    teacherId: number
  ): Observable<BaseResponse<string, number>> {
    return this.http
      .delete(AuthUrls.removeUser(this.baseUrl).replace(':UserId', teacherId.toString()))
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          return data;
        })
      );
  }

  issueCompleteHourCertificate(
    req: IssueCertificateModel
  ): Observable<BaseResponse<string, IssueCertificateModel>> {
    return this.http
      .post(AuthUrls.issueCompleteHourCertificate(this.baseUrl), req)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, IssueCertificateModel> = res;
          return data;
        })
      );
  }

  getCertificateDetail(
    UniqueKey: string
  ): Observable<BaseResponse<CertificateDetailModle, string>> {
    return this.http
      .get(AuthUrls.getCertificateDetail(this.baseUrl).replace(':UniqueKey', UniqueKey))
      .pipe(
        map((res) => {
          const data: BaseResponse<CertificateDetailModle, string> = res;
          return data;
        })
      );
  }

  CloneTeacher(
    request: CloneTeacherRequest
  ): Observable<BaseResponse<string, CloneTeacherRequest>> {
    return this.http
      .post(AuthUrls.cloneTeacher(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, CloneTeacherRequest> = res;
          return data;
        })
      );
  }

  public loginService(
    request: Login
  ): Observable<BaseResponse<LoginResponse, Login>> {
    return this.http.post(AuthUrls.login(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<LoginResponse, Login> = res;
        return data;
      })
    );
  }

  public createUserService(
    request: CreateUser
  ): Observable<BaseResponse<LoginResponse, CreateUser>> {
    return this.http.post(AuthUrls.createUser(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<LoginResponse, CreateUser> = res;
        return data;
      })
    );
  }

  public updateUserService(
    request: UserProfile
  ): Observable<BaseResponse<string, UserProfile>> {
    return this.http.post(AuthUrls.updateUser(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, UserProfile> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getUserProfile(): Observable<BaseResponse<UserProfile, string>> {
    return this.http.get(AuthUrls.getUserProfile(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<UserProfile, string> = res;
        return data;
      })
    );
  }

  public loginAsUser(email: string): Observable<BaseResponse<LoginResponse, string>> {
    return this.http.post(AuthUrls.loginWithoutPasswordCommand(this.baseUrl), {email}).pipe(
      map((res) => {
        const data: BaseResponse<LoginResponse, string> = res;
        return data;
      })
    );
  }

  public forgotPassword(
    email: string
  ): Observable<BaseResponse<string, string>> {
    return this.http
      .post(AuthUrls.forgotPassword(this.baseUrl), {email})
      .pipe(
        map((res) => {
          const data: BaseResponse<string, string> = res;
          return data;
        })
      );
  }

  public confirmEmail(
    code: string,
    userId: number
  ): Observable<BaseResponse<string, { code: string; userId: number }>> {
    return this.http
      .post(AuthUrls.confirmEmail(this.baseUrl), {code, userId})
      .pipe(
        map((res) => {
          const data: BaseResponse<string, { code: string; userId: number }> =
            res;
          data.request = {code, userId};
          return data;
        })
      );
  }

  public resetPassword(
    code: string,
    userId: number,
    password: string
  ): Observable<BaseResponse<string, { code: string; userId: number; password: string }>> {
    return this.http
      .post(AuthUrls.resetPassword(this.baseUrl), {code, userId, password})
      .pipe(
        map((res) => {
          const data: BaseResponse<string,
            { code: string; userId: number; password: string }> = res;
          data.request = {code, userId, password};
          return data;
        })
      );
  }

  public submitMyInterest(
    request: ISubmitInterest
  ): Observable<BaseResponse<string, ISubmitInterest>> {
    return this.http
      .post(AuthUrls.submitMyInterest(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, ISubmitInterest> = res;
          data.request = request;
          return data;
        })
      );
  }

  public inviteBetaUser(
    request: ISubmitInterest
  ): Observable<BaseResponse<string, ISubmitInterest>> {
    return this.http
      .post(AuthUrls.inviteBetaUser(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, ISubmitInterest> = res;
          data.request = request;
          return data;
        })
      );
  }


  public inviteForRegistration(
    request: ISubmitInterest
  ): Observable<BaseResponse<string, ISubmitInterest>> {
    return this.http
      .post(AuthUrls.inviteForRegistration(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, ISubmitInterest> = res;
          data.request = request;
          return data;
        })
      );
  }

  public togglePreviledgeFlag(userId: string, isPrivileged: boolean): Observable<BaseResponse<string, string>> {
    return this.http.get(AuthUrls.togglePreviledgeFlag(this.baseUrl).replace(':userId', userId)).pipe(
      map((res) => {
        const data: BaseResponse<string, string> = res;
        data.request = userId;
        data.queryString = isPrivileged;
        return data;
      })
    );
  }

  public addRegistrationRequests(request: AddRegistrationRequests): Observable<BaseResponse<string, AddRegistrationRequests>> {
    return this.http
      .post(AuthUrls.addRegistrationRequests(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, AddRegistrationRequests> = res;
          data.request = request;
          return data;
        })
      );
  }

  getAllRegistrationRequest(
    filterRequest: BaseFilterRequest
  ): Observable<BaseResponse<PaginatedResponse<RegistrationRequest[]>, BaseFilterRequest>> {
    return this.http
      .post(AuthUrls.getAllRegistrationRequest(this.baseUrl), filterRequest)
      .pipe(
        map((res) => {
          const data: BaseResponse<PaginatedResponse<RegistrationRequest[]>,
            BaseFilterRequest> = res;
          data.request = filterRequest;
          return data;
        })
      );
  }

  public sendRegistrationEmailCommand(id: string): Observable<BaseResponse<string, string>> {
    return this.http.get(AuthUrls.sendRegistrationEmailCommand(this.baseUrl).replace(':Id', id)).pipe(
      map((res) => {
        const data: BaseResponse<string, string> = res;
        data.request = id;
        return data;
      })
    );
  }

  public getRegistrationRequestById(Key: string): Observable<BaseResponse<RegistrationRequestById, string>> {
    return this.http.get(AuthUrls.getRegistrationRequestById(this.baseUrl).replace(':Key', Key)).pipe(
      map((res) => {
        const data: BaseResponse<RegistrationRequestById, string> = res;
        return data;
      })
    );
  }


  getStateOrRegions(): Observable<BaseResponse<string[], string>> {
    return this.http
      .get(AuthUrls.getStateOrRegions(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<string[], string> = res;
          return data;
        })
      );
  }

  getAllRoles(): Observable<BaseResponse<string[], string>> {
    return this.http
      .get(AuthUrls.getAllRoles(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<string[], string> = res;
          return data;
        })
      );
  }

  public addRequestForDemo(request: AddRequestForDemo): Observable<BaseResponse<string, AddRequestForDemo>> {
    return this.http
      .post(AuthUrls.addRequestForDemo(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, AddRequestForDemo> = res;
          data.request = request;
          return data;
        })
      );
  }

  public getUserUnreadCountService(): Observable<BaseResponse<boolean, string>> {
    return this.http.get(AuthUrls.getUserUnreadCount(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<boolean, string> = res;
        return data;
      })
    );
  }
}
