import {UserProfile} from '../user/userProfile';

export class Login {
  email: string;
  password: string;
}

export class LoginResponse {
  token: string;
  notBefore?: number;
  expires?: string;
  user: UserProfile;
  refreshToken?: string;
  isSessionUser?: boolean;

}

export class RefreshTokenDto {
  token: string;
  refreshToken: string;
}

export class ProductList {
  domain: string;
  siteName: string;
  siteToken: string;
}

export class ExternalLogin {
  token: string;
  provider: string;
  redirect_Url: string;
}

export class CreateUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  latitude: number;
  longitude: number;
  password: string;
  timeZone: string;
  allowContactMe: boolean;
  betaToken: string;
}

export interface UpdateUser {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  profilePicKey: string;
  removeProfilePic: boolean;
  address1?: string;
  address2?: string;
  streetAddress?: string;
  city?: string;
  zipcode?: string;
  country?: string;
}

export interface ISubmitInterest {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
}

export interface LoginResponse {
  token: string;
  user: UserProfile;
}

export class AddRequestForDemo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  schoolOrDistrict: string;
  stateOrRegion: string;
  role: string;
  jobTitle: string;
  subscribeForUpdatesAndResources: boolean;
  whereDidYouHear:string;
}
