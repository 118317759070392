export const environment = {
  production: true,
  apiUrl: 'https://thereadingfoundation.azurewebsites.net/api/',
  appUrl: 'https://trf.mypathtolearning.com/',
  environmentName: 'production',
  appInsights: {
    instrumentationKey: '34b8430f-546e-40d5-9770-4bc87f72b4ce',
  },
  version: "2.1.7"
};
