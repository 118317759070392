import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastService } from './services/toast.service';
import { ToasterComponent } from './services/toast.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, MatIconModule, MatButtonModule],
  declarations:[ToasterComponent]
})
export class UiToastModule {
  static forRoot(): ModuleWithProviders<UiToastModule> {
    return {
      ngModule: UiToastModule,
      providers: [ToastService],
    };
  }
}
