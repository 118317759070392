import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { IAppState, isPrivileged, isToasterAdmin } from '@mptl/models';
import { ComponentType } from '@angular/cdk/overlay';
import { ToasterComponent } from './toast.component';

@Injectable()
export class ToastService {

  isToasterAdmin: boolean = false;
  constructor(private _snackBar: MatSnackBar, private store: Store<IAppState>) {
    this.store
      .pipe(select(isToasterAdmin))
      .subscribe((res) => (this.isToasterAdmin = res));
  }

  private show(
    textOrTpl: string | TemplateRef<any>,
    data = null,
    options: { duration: number; panelClass?: string } = { duration: 5000 }
  ) {
    if (textOrTpl instanceof TemplateRef) {
      this._snackBar.openFromTemplate(textOrTpl, {
        ...options,
        data: data,
        duration: options.duration,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    } else {
      this._snackBar.open(textOrTpl, '', {
        ...options,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }
  }
  private showToaster(textOrTpl: ComponentType<any>, data: any, options: { panelClass?: string }) {
    this._snackBar.openFromComponent(textOrTpl, {
      ...options,
      data: data,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });

  }

  public error(textOrTpl: string | TemplateRef<any>, data = null) {
    if(this.isToasterAdmin){
      this.showToaster(ToasterComponent,textOrTpl, { panelClass: 'error-toast' });
    }else{
      this.show(textOrTpl, data, { duration: 5000, panelClass: 'error-toast' });
    }
  }

  public warn(textOrTpl: string | TemplateRef<any>, data = null) {
    if (this.isToasterAdmin){
      this.showToaster(ToasterComponent,textOrTpl, {panelClass: 'warn-toast' });
    }else{
      this.show(textOrTpl, data, { duration: 5000, panelClass: 'warn-toast' });
    }
  }
  public success(textOrTpl: string | TemplateRef<any>, data = null) {
    this.show(textOrTpl, data, { duration: 5000, panelClass: 'success-toast' });
  }
}
