import {Component, Inject, Renderer2} from "@angular/core";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'app-toaster',
  templateUrl:'./toast.component.html',
})
export class ToasterComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data : string,
    public _snackRef: MatSnackBarRef<ToasterComponent>,
    private ren:Renderer2
  ) {
    setTimeout(()=>{
      // @ts-ignore
      let snackEl = document.getElementsByClassName('mat-snack-bar-container').item(0);
      ren.listen(snackEl, 'click', ()=>this.dismiss())
    })
  }

  dismiss(){
    this._snackRef.dismiss();
  }
}
